import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ApolloClient, InMemoryCache, ApolloProvider, gql } from '@apollo/client';

const custom_headers = {
  "x-hasura-admin-secret": "qZdDzVZJ1yiwfPQyAN2Uk94R4knLc4IwauurCA7MHqchHRA47g6LrkVwpiHj7Cl3",
  "Content-Type": "application/json",
};

const client = new ApolloClient({
  uri: 'https://inspired-macaque-11.hasura.app/v1/graphql',
  headers: custom_headers,
  cache: new InMemoryCache(),
});



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
    <App />
    </ApolloProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
