import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom/client';
import { useQuery, gql } from '@apollo/client';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import { CSVLink, CSVDownload } from "react-csv";

import './index.css'

import {
    Column,
    Table,
    useReactTable,
    getCoreRowModel,
    getFilteredRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFacetedMinMaxValues,
    getPaginationRowModel,
    sortingFns,
    getSortedRowModel,
    FilterFn,
    SortingFn,
    ColumnDef,
    flexRender,
    FilterFns,
} from '@tanstack/react-table'

import {
    RankingInfo,
    rankItem,
    compareItems,
} from '@tanstack/match-sorter-utils'


const fuzzyFilter = (row, columnId, value, addMeta) => {
    // Rank the item
    const itemRank = rankItem(row.getValue(columnId), value)

    // Store the itemRank info
    addMeta({
        itemRank,
    })

    // Return if the item should be filtered in/out
    return itemRank.passed
}

const fuzzySort = (rowA, rowB, columnId) => {
    let dir = 0

    // Only sort by rank if the column has ranking information
    if (rowA.columnFiltersMeta[columnId]) {
        dir = compareItems(
            rowA.columnFiltersMeta[columnId].itemRank,
            rowB.columnFiltersMeta[columnId].itemRank
        )
    }

    // Provide an alphanumeric fallback for when the item ranks are equal
    return dir === 0 ? sortingFns.alphanumeric(rowA, rowB, columnId) : dir
}

const GET_DOWNLOADS = gql`
  query GetDownloads($fromDate: timestamptz, $toDate: timestamptz) {
    downloads_analytics{
      download_file_name
      id
      path
      user_id
      user_name
      timestamp
    }
  }
`;

function App() {
    const rerender = React.useReducer(() => ({}), {})[1]
    const [fromDate, onChangeFromDate] = useState(new Date());
    const [toDate, onChangeToDate] = useState(new Date());

    const [columnFilters, setColumnFilters] = React.useState(
        []
    )
    const [globalFilter, setGlobalFilter] = React.useState('')

    const columns = React.useMemo(
        () => [
            {
                header: 'User Details',
                footer: props => props.column.id,
                columns: [
                    {
                        accessorFn: row => row.user_name,
                        id: 'user_name',
                        cell: info => info.getValue(),
                        header: () => <span>User Name</span>,
                        footer: props => props.column.id,
                    },
                    {
                        accessorFn: row => row.user_id,
                        id: 'user_id',
                        cell: info => info.getValue(),
                        header: () => <span>User Id</span>,
                        footer: props => props.column.id,
                    },
                ],
            },
            {
                header: 'Info',
                footer: props => props.column.id,
                columns: [
                    {
                        accessorKey: 'download_file_name',
                        header: () => 'Download File Name',
                        footer: props => props.column.id,
                    },
                    {
                        accessorKey: 'timestamp',
                        header: () => 'Time and date',
                        footer: props => props.column.id,
                        cell: info => info.getValue(),
                    },
                ],
            },
        ],
        []
    )

    //   const [data, setData] = React.useState(() => makeData(50000))
    const { loading, error, data, refetch: refetchDownloads } = useQuery(GET_DOWNLOADS, {
        variables: {
            fromDate,
            toDate
        },
    });

    console.log(data, error, fromDate, toDate)

    useEffect(() => {
        // refetchDownloads({
        //     fromDate, 
        //     toDate
        // })
    }, [fromDate, toDate])
    //   const refreshData = () => setData(old => makeData(50000))

    const table = useReactTable({
        data: data ? data.downloads_analytics : [],
        columns,
        filterFns: {
            fuzzy: fuzzyFilter,
        },
        state: {
            columnFilters,
            globalFilter,
        },
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        globalFilterFn: fuzzyFilter,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        debugTable: true,
        debugHeaders: true,
        debugColumns: false,
    })


    return (
        <div className='main--container p-2'>
            <div>Downloads</div>
            <div className="padding--10">Time filter: By default monthly report</div>
            {/* <DatePicker className="padding--10" onChange={onChangeFromDate} value={fromDate} />
            <DatePicker className="padding--10" onChange={onChangeToDate} value={toDate || new Date().now()} /> */}
            {/* <div>
                <div>
                    <DebouncedInput
                        value={globalFilter ?? ''}
                        onChange={value => setGlobalFilter(String(value))}
                        className="p-2 font-lg shadow border border-block"
                        placeholder="Search all columns..."
                    />
                </div>
                <div className="h-2" />
                <div className="table--container">
                    <table>
                        <thead>
                            {table.getHeaderGroups().map(headerGroup => (
                                <tr key={headerGroup.id}>
                                    {headerGroup.headers.map(header => {
                                        return (
                                            <th key={header.id} colSpan={header.colSpan}>
                                                {header.isPlaceholder ? null : (
                                                    <>
                                                        <div
                                                            {...{
                                                                className: header.column.getCanSort()
                                                                    ? 'cursor-pointer select-none'
                                                                    : '',
                                                                onClick: header.column.getToggleSortingHandler(),
                                                            }}
                                                        >
                                                            {flexRender(
                                                                header.column.columnDef.header,
                                                                header.getContext()
                                                            )}
                                                            {{
                                                                asc: ' 🔼',
                                                                desc: ' 🔽',
                                                            }[header.column.getIsSorted()] ?? null}
                                                        </div>
                                                        {header.column.getCanFilter() ? (
                                                            <div>
                                                                <Filter column={header.column} table={table} />
                                                            </div>
                                                        ) : null}
                                                    </>
                                                )}
                                            </th>
                                        )
                                    })}
                                </tr>
                            ))}
                        </thead>
                        <tbody>
                            {table.getRowModel().rows.map(row => {
                                return (
                                    <tr key={row.id}>
                                        {row.getVisibleCells().map(cell => {
                                            return (
                                                <td key={cell.id}>
                                                    {flexRender(
                                                        cell.column.columnDef.cell,
                                                        cell.getContext()
                                                    )}
                                                </td>
                                            )
                                        })}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
                <div className="h-2" />
                <div className="flex items-center gap-2">
                    <button
                        className="border rounded p-1"
                        onClick={() => table.setPageIndex(0)}
                        disabled={!table.getCanPreviousPage()}
                    >
                        {'<<'}
                    </button>
                    <button
                        className="border rounded p-1"
                        onClick={() => table.previousPage()}
                        disabled={!table.getCanPreviousPage()}
                    >
                        {'<'}
                    </button>
                    <button
                        className="border rounded p-1"
                        onClick={() => table.nextPage()}
                        disabled={!table.getCanNextPage()}
                    >
                        {'>'}
                    </button>
                    <button
                        className="border rounded p-1"
                        onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                        disabled={!table.getCanNextPage()}
                    >
                        {'>>'}
                    </button>
                    <span className="flex items-center gap-1">
                        <div>Page</div>
                        <strong>
                            {table.getState().pagination.pageIndex + 1} of{' '}
                            {table.getPageCount()}
                        </strong>
                    </span>
                    <span className="flex items-center gap-1">
                        | Go to page:
                        <input
                            type="number"
                            defaultValue={table.getState().pagination.pageIndex + 1}
                            onChange={e => {
                                const page = e.target.value ? Number(e.target.value) - 1 : 0
                                table.setPageIndex(page)
                            }}
                            className="border p-1 rounded w-16"
                        />
                    </span>
                    <select
                        value={table.getState().pagination.pageSize}
                        onChange={e => {
                            table.setPageSize(Number(e.target.value))
                        }}
                    >
                        {[10, 20, 30, 40, 50].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                                Show {pageSize}
                            </option>
                        ))}
                    </select>
                </div>
                <div>{table.getPrePaginationRowModel().rows.length} Rows</div>
                <div>
                    <button onClick={() => rerender()}>Force Rerender</button>
                </div>
            </div> */}
            <div>
                {data && <CSVLink data={data.downloads_analytics} filename={"Download.csv"} style={{fontSize: "16px", margin: "10px 5px"}}>All downloads with date and time</CSVLink>}
            </div>
            <div>
                {data && <CSVLink data={downloadsConsolidated(data.downloads_analytics)} filename={`Downloads Consolidated.csv`} style={{fontSize: "16px", margin: "10px 5px"}}>Download ( consolidated, Number of downlaods per document )</CSVLink>}
            </div>
            <div>
                {/* <button onClick={() => refreshData()}>Refresh Data</button> */}
            </div>
            {/* <pre>{JSON.stringify(table.getState(), null, 2)}</pre> */}
        </div>
    )
}
function downloadsConsolidated(data) {
    let downloadObj = {}
    data.map(eachDownload => {
        if (!downloadObj[eachDownload.user_name]) {
            downloadObj[eachDownload.user_name] = {
                [eachDownload.download_file_name]: 1
            }
        } else {
            if (!downloadObj[eachDownload.user_name][eachDownload.download_file_name]) {
                downloadObj[eachDownload.user_name][eachDownload.download_file_name] = 1
            } else {
                downloadObj[eachDownload.user_name][eachDownload.download_file_name] = downloadObj[eachDownload.user_name][eachDownload.download_file_name] + 1
            }
        }
    })

    let finalData = []
    Object.keys(downloadObj).map((key) => {
        Object.keys(downloadObj[key]).map(file => {
            finalData.push({ "User Name": key, "Download file name": file, "Count": downloadObj[key][file] })
        })
    })

    return finalData
}
function Filter({
    column,
    table,
}) {
    const firstValue = table
        .getPreFilteredRowModel()
        .flatRows[0]?.getValue(column.id)

    const columnFilterValue = column.getFilterValue()

    const sortedUniqueValues = React.useMemo(
        () =>
            typeof firstValue === 'number'
                ? []
                : Array.from(column.getFacetedUniqueValues().keys()).sort(),
        [column.getFacetedUniqueValues()]
    )

    return typeof firstValue === 'number' ? (
        <div>
            <div className="flex space-x-2">
                <DebouncedInput
                    type="number"
                    min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
                    max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
                    value={(columnFilterValue)?.[0] ?? ''}
                    onChange={value =>
                        column.setFilterValue((old) => [value, old?.[1]])
                    }
                    placeholder={`Min ${column.getFacetedMinMaxValues()?.[0]
                        ? `(${column.getFacetedMinMaxValues()?.[0]})`
                        : ''
                        }`}
                    className="w-24 border shadow rounded"
                />
                <DebouncedInput
                    type="number"
                    min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
                    max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
                    value={(columnFilterValue)?.[1] ?? ''}
                    onChange={value =>
                        column.setFilterValue((old) => [old?.[0], value])
                    }
                    placeholder={`Max ${column.getFacetedMinMaxValues()?.[1]
                        ? `(${column.getFacetedMinMaxValues()?.[1]})`
                        : ''
                        }`}
                    className="w-24 border shadow rounded"
                />
            </div>
            <div className="h-1" />
        </div>
    ) : (
        <div>
            <datalist id={column.id + 'list'}>
                {sortedUniqueValues.slice(0, 5000).map((value) => (
                    <option value={value} key={value} />
                ))}
            </datalist>
            <DebouncedInput
                type="text"
                value={(columnFilterValue ?? '')}
                onChange={value => column.setFilterValue(value)}
                placeholder={`Search... (${column.getFacetedUniqueValues().size})`}
                className="w-36 border shadow rounded"
                list={column.id + 'list'}
            />
            <div className="h-1" />
        </div>
    )
}

// A debounced input react component
function DebouncedInput({
    value: initialValue,
    onChange,
    debounce = 500,
    ...props
}) {
    const [value, setValue] = React.useState(initialValue)

    React.useEffect(() => {
        setValue(initialValue)
    }, [initialValue])

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            onChange(value)
        }, debounce)

        return () => clearTimeout(timeout)
    }, [value])

    return (
        <input {...props} value={value} onChange={e => setValue(e.target.value)} />
    )
}

export default App


