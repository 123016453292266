export const genrateReport = (allPageData, allSessionData) => {
    // console.log(allPageData, allSessionData, "Here")

    let firstSession = {};
    let firstTimeData = []
    let singleUserData = {};
    let singleUserDataUsed = {};
    allPageData.page_session.map(page => {
        if (singleUserData[page.user_name]) {
            singleUserData[page.user_name].push(page)
        } else {
            singleUserData[page.user_name] = [page]
            singleUserDataUsed[page.user_name] = {}
        }
    })

    // console.log(singleUserData, "singleUserData")

    let finalData = [];
    let c = 0;

    if (allSessionData && allSessionData.user_session) {
        // allSessionData.user_session.forEach(userSession => {
        // if(!firstSession[userSession.user_name]){
        //     firstSession[userSession.user_name] = userSession.logginTime
        // }else{
        //     if(firstSession[userSession.user_name] > userSession.logginTime){
        //         firstSession[userSession.user_name] = userSession.logginTime
        //     }
        // }})
        if (true) {
            allSessionData.user_session.forEach(userSession => {
                const currentUser = singleUserData[userSession.user_name]
                let totalTimeOnPages = {}
                let skipSessionForTooLong = false
                if (currentUser && currentUser.length > 0) {
                    currentUser.forEach(userPageVisit => {

                        if (userPageVisit.start_time >= userSession.logginTime * 1000 && userPageVisit.end_time <= (userSession.loggoutTime + 1) * 1000 && (( (userSession.loggoutTime + 1) * 1000 ) - (userSession.logginTime * 1000)) < 8*60*60*1000) {
                            singleUserDataUsed[userSession.user_name][userPageVisit.id] = true
                            if (!totalTimeOnPages[userPageVisit.path]) {
                                totalTimeOnPages[userPageVisit.path] = userPageVisit.total_time
                            } else {
                                totalTimeOnPages[userPageVisit.path] += userPageVisit.total_time
                            }
                        }

                    })

                    if((((userSession.loggoutTime + 1) * 1000 ) - (userSession.logginTime * 1000)) > 8*60*60*1000){
                        skipSessionForTooLong = true
                    }
                    // if(firstSession[userSession.user_name] === userSession.logginTime){
                    //     firstTimeData.push({
                    //         "Date": ( new Date(userSession.logginTime*1000)).toDateString(),
                    //         "User name": userSession.user_name,
                    //         "Log in time": ( new Date(userSession.logginTime*1000)).toString(),
                    //         "Log out time": ( new Date(userSession.loggoutTime*1000)).toString(),
                    //         "Session Duration": userSession.session_duration,
                    //         ...totalTimeOnPages
                    //     })
                    // }
                    if(!skipSessionForTooLong)
                    finalData.push({
                        "Date": ( new Date(userSession.logginTime*1000)).toDateString(),
                        "User name": userSession.user_name,
                        "Log in time": ( new Date(userSession.logginTime*1000)).toString(),
                        "Log out time": ( new Date(userSession.loggoutTime*1000)).toString(),
                        "Session Duration": userSession.session_duration,
                        ...totalTimeOnPages
                    })
                }
            })
        }

        let allSessionsWithoutLog = []
        let totalTimeOnPages = {}
        Object.keys(singleUserData).map(eachUser => {
            let all = singleUserData[eachUser]
            singleUserData[eachUser] = all.sort((a, b) => a.start_time - b.start_time).filter(userPageRecord => !singleUserDataUsed[eachUser][userPageRecord.id])
                
        })
        // singleUserData {"saikiran.bodapati@ferring.com": null}
        Object.keys(singleUserData).forEach(user => {
            const currentUserList = singleUserData[user].sort((a, b) => a.start_time - b.start_time)
            console.log(currentUserList)
            let initTime = 0
            let totalTimeOnPages = {}
            let logginTime = 0
            if(currentUserList.length > 0){
                logginTime = currentUserList[0].start_time
            }
            let session_duration = 0;
            for(let i = 0; i < currentUserList.length ; i++){
                // debugger
                if(i === 0){
                    initTime = currentUserList[i].end_time
                }else{
                    initTime = currentUserList[i - 1].end_time
                }
                
                if(initTime + 7200000 > currentUserList[i].start_time){
                    session_duration  += currentUserList[i].total_time
                    if (!totalTimeOnPages[currentUserList[i].path]) {
                        totalTimeOnPages[currentUserList[i].path] = currentUserList[i].total_time
                    } else {
                        totalTimeOnPages[currentUserList[i].path] += currentUserList[i].total_time
                    }
                }else{
                    finalData.push({
                        "Date": ( new Date(logginTime)).toDateString(),
                        "User name": user,
                        "Log in time": ( new Date(logginTime)).toString(),
                        "Log out time": "",
                        "Session Duration": session_duration,
                        ...totalTimeOnPages
                    })
                    logginTime = currentUserList[i].start_time
                    totalTimeOnPages = {}
                    session_duration = currentUserList[i].total_time
                    if (!totalTimeOnPages[currentUserList[i].path]) {
                        totalTimeOnPages[currentUserList[i].path] = currentUserList[i].total_time
                    } else {
                        totalTimeOnPages[currentUserList[i].path] += currentUserList[i].total_time
                    }
                }

                if(i + 1 === currentUserList.length){
                    finalData.push({
                        "Date": ( new Date(logginTime)).toDateString(),
                        "User name": user,
                        "Log in time": ( new Date(logginTime)).toString(),
                        "Log out time": "",
                        "Session Duration": session_duration,
                        ...totalTimeOnPages
                    })
                }
            }
        })

        finalData.forEach(userSession => {
            if(!firstSession[userSession["User name"]]){
                firstSession[userSession["User name"]] = (new Date(userSession["Log in time"])).getTime()
            }else{
                if(firstSession[userSession["User name"]] > ( new Date(userSession["Log in time"])).getTime()){
                    firstSession[userSession["User name"]] = (new Date(userSession["Log in time"])).getTime()
                }
            }})

        finalData.forEach(userSession => {
            if(firstSession[userSession["User name"]] === (new Date(userSession["Log in time"])).getTime()){
                        firstTimeData.push(userSession)
            }
        })

        // console.log(finalData, firstTimeData, singleUserData, singleUserDataUsed, "finalData, firstTimeData, singleUserData, singleUserDataUsed")


        
        return { "finalData" : finalData, "firstTimeData": firstTimeData}

    }

    console.log(finalData)
    return [];
}