import logo from './logo.svg';
import './App.css';
import React, { useState } from 'react'
import Downloads from './Downloads'
import PageActivity from './PageActivity'
import UserSession from './UserActivity'
import PageTime from './PageTimeInterval'


function App() {

  const [page, selectedPage] = useState("downloads")

  return (
    <div className="App">
      <div className='sidebar'>
        <div onClick={() => selectedPage("downloads")}>
          Downloads
        </div>
        <div onClick={() => selectedPage("page_activity")}>
          Page Activity
        </div>
        <div onClick={() => selectedPage("user_activity")}>
          User Activity
        </div>
        <div onClick={() => selectedPage("page_time_spent")}>
          Page Time spent
        </div>

      </div>
      <div>
        {page == "downloads" && <Downloads />}
        {page === "page_activity" && <PageActivity />}
        {page === "user_activity" && <UserSession />}
        {page === "page_time_spent" && <PageTime />}
      </div>
    </div>
  );
}

export default App;
